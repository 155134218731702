import Utils from "./Utils";
import Com from "./Communicator";

class ApiClass {
  constructor(url) {
    this.url = url;
    this.working = true;
  }

  get = async (urlPath, filters = null, options = null) => {
    if (this.working === false) {
      return null;
    }
    let uri = urlPath;
    if (!Utils.emptyObject(filters)) {
      uri += "?" + Utils.objectToQueryParams(filters);
    }
    const data = await Com.requestJson(uri, options);
    if (
      !data ||
      !data["documentation_url"] ||
      !data["documentation_url"] ===
        "https://developer.github.com/v3/#rate-limiting"
    ) {
      return data;
    } else {
      this.working = false;
    }
    return null;
  };

  getInfo = async (filters = null) => {
    return await this.get(this.url, filters);
  };

  getProjects = async (filters = null) => {
    return await this.get(this.url + "/repos", filters);
  };
}

const Api = new ApiClass("https://api.github.com/users/juanfkurucz");
export default Api;
