import React from "react";

const Footer = () => (
  <footer className="footer">
    <span>
      Header background photo by Markus Spiske{" "}
      <a
        href="https://www.pexels.com/photo/creative-internet-computer-display-2004161/"
        rel="noopener noreferrer"
        target="_blank"
      >
        temporausch.com from Pexels
      </a>
    </span>
    <br />
    <span>Developed with love using ReactJs - JuanFKurucz 2020</span>
  </footer>
);

export default Footer;
