import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import GithubAlert from "./Header/GithubAlert";
import HeaderBackground from "./Header/HeaderBackground";
import Api from "../utils/Api";
import HeaderStats from "./Header/HeaderStats";

const Header = () => {
  const offlineInfo = require("assets/json/info.json");

  const [username, setUsername] = useState(offlineInfo.username);
  const [name, setName] = useState(offlineInfo.name);
  const [bio, setBio] = useState(offlineInfo.bio);
  const [avatar, setAvatar] = useState(offlineInfo.avatar);
  const [visibleAlert, setVisibleAlert] = useState(true);
  const [stats, setStats] = useState(offlineInfo.stats);

  const getInfo = async () => {
    const data = await Api.getInfo();
    if (data !== null) {
      setUsername(data.login);
      setName(data.name);
      setBio(data.bio);
      setAvatar(data.avatar_url);
      setVisibleAlert(false);
      setStats(() => {
        return [
          {
            name: "Repositorios públicos",
            value: data.public_repos
          },
          {
            name: "Seguidores en GitHub",
            value: data.followers
          }
        ];
      });
    }
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <>
      <GithubAlert visible={visibleAlert} />
      <HeaderBackground />
      <Container className="header">
        <div className="profilePictureContainer">
          <img alt={"Foto de perfil de " + username} src={avatar}></img>
        </div>
        <h3 className="title">
          {name} ({username})
        </h3>
        <p className="bio">{bio}</p>
        <HeaderStats stats={stats} />
      </Container>
    </>
  );
};
export default Header;
