class Communicator {
  static async requestJson(url, options = null) {
    const optionsDict = { ...options, mode: "no-cors" };
    const requestObject = await fetch(url, optionsDict);
    if (requestObject.body) {
      const data = await requestObject.json();
      if (data) {
        return data;
      }
    }
    return null;
  }
}

export default Communicator;
