import React, { useState, useEffect } from "react";
import Loader from "react-loader";
import { Row, Col } from "reactstrap";
import Api from "utils/Api";

const Projects = () => {
  const [projectList, setProjects] = useState(null);
  const [ready, setReady] = useState(false);

  const generateDate = (date) => {
    let horas = ("" + date.getHours()).padStart(2, "0");
    let minutos = ("" + date.getMinutes()).padStart(2, "0");
    let dia = ("" + date.getDate()).padStart(2, "0");
    let mes = ("" + (date.getMonth() + 1)).padStart(2, "0");
    let año = ("" + date.getFullYear()).padStart(4, "0");
    return horas + ":" + minutos + " " + dia + "/" + mes + "/" + año;
  };

  useEffect(() => {
    const createProjects = (dataList) => {
      let projects = [];
      if (dataList != null) {
        let key = 0;
        for (let e in dataList) {
          let data = dataList[e];
          if (!data.fork) {
            let lenguajes = [];
            let lineas = 0;
            if (data["language_list"]) {
              for (let i in data["language_list"]) {
                lineas += data["language_list"][i];
              }
              for (let i in data["language_list"]) {
                let porcentaje = (
                  (data["language_list"][i] / lineas) *
                  100
                ).toFixed(2);
                lenguajes.push(i + ": " + porcentaje + "%");
              }
              lenguajes = lenguajes.join(", ");
            } else {
              lenguajes = data["language"];
            }

            let fecha_creacion = generateDate(new Date(data.created_at));
            let fecha_actualizacion = generateDate(new Date(data.pushed_at));
            projects.push(
              <Col md="6" key={key}>
                <br />
                <center>
                  <b>{data.name}</b>
                  <br />
                  <span>{data.description}</span>
                </center>
                <b>Fecha creacion:</b> {fecha_creacion}
                <br />
                <b>Ultima actualizacion:</b> {fecha_actualizacion}
                <br />
                <b>Creador:</b> {data.owner.login}
                <br />
                <b>Lenguajes:</b> {lenguajes}
                <br />
                <b>Ver repositorio:</b>{" "}
                <a
                  href={data.html_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {data.full_name}
                </a>
                <br />
              </Col>
            );
            key++;
          }
        }
        setProjects(projects);
      }
      setReady(true);
    };

    const loadProjects = async () => {
      let data = await Api.getProjects();
      if (data !== null) {
        data.sort(function (a, b) {
          let time1 = new Date(a.pushed_at);
          let time2 = new Date(b.pushed_at);
          return time2.getTime() - time1.getTime();
        });
        for (let d in data) {
          data[d]["language_list"] = await Api.get(data[d]["languages_url"]);
        }
      } else {
        data = require("assets/json/projects.json");
      }
      createProjects(data);
    };

    loadProjects();
  }, []);

  return (
    <Row>
      <Col className="ml-auto mr-auto" md="6">
        <h4 className="title text-center">Proyectos públicos en GitHub</h4>
      </Col>
      <Col className="ml-auto mr-auto" md="10">
        <Loader loaded={ready}>
          <Row className="collections">{projectList}</Row>
        </Loader>
      </Col>
    </Row>
  );
};
export default Projects;
