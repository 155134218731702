import React from "react";
import { Alert } from "reactstrap";

const GithubAlert = props => (
  <Alert color="warning" isOpen={props.visible} style={{ margin: "0" }}>
    <div className="container">
      <div className="alert-icon">
        <i className="now-ui-icons arrows-1_refresh-69"></i>
      </div>
      No se pudo comunicar con la <strong>API de GitHub</strong>. Se cargo
      informacion local
    </div>
  </Alert>
);
export default GithubAlert;
