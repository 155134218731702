import React from "react";
import { Row, Col } from "reactstrap";

const AboutMe = () => (
  <>
    <Row>
      <Col className="ml-auto mr-auto" md="6">
        <h4 className="title text-center">Sobre mi</h4>
      </Col>
    </Row>
    <Row>
      <Col className="ml-auto mr-auto" md="10">
        Soy estudiante de Ingeniería en Informática de la Universidad Católica
        del Uruguay buscando siempre obtener nuevos conocimientos y crear
        herramientas para el futuro. <br />
        Además he trabajado para múltiples empresas y organizaciones brindando
        diversas soluciones a problemáticas utilizando tecnologías de
        vanguardia.
      </Col>
    </Row>
  </>
);

export default AboutMe;
