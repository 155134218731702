import React from "react";

const HeaderStats = props => (
  <div>
    {props.stats &&
      props.stats.map((stat, index) => (
        <div className="stats" key={index}>
          <h2>{stat.value}</h2>
          <p>{stat.name}</p>
        </div>
      ))}
  </div>
);
export default HeaderStats;
