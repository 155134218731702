import React from "react";

const HeaderBackground = () => (
  <>
    <div className="background colorBackgroundContainer"></div>
    <div
      className="background backgroundContainer"
      style={{
        backgroundImage: "url(" + require("assets/img/background.jpg") + ")"
      }}
    ></div>
  </>
);
export default HeaderBackground;
