import React from "react";
import { Container } from "reactstrap";

import Header from "components/Header";
import AboutMe from "components/AboutMe";
import Projects from "components/Lists/Projects";
import Footer from "components/Footer";

const About = () => {
  return (
    <div className="wrapper">
      <Header />
      <div className="section">
        <Container>
          <AboutMe />
        </Container>
        <Container>
          <Projects />
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default About;
